<template>
    <div
        class="w-full bg-no-repeat bg-cover bg-right-bottom rounded py-8 pb-12 relative cursor-pointer"
        style="background-image: url(/static-web/images/widgets/victory/victory-banner.png); height: 226px"
        @click="handleOpen"
    >
        <div class="polls-slider__banner-row">
            <span class="polls-slider__category-wrap space-x-3">
                <img
                    src="/static-web/images/widgets/victory/victory-icon.png"
                    alt="Иконка уведомления"
                />
                <span class="victory-title">
                    80 лет Победы
                </span>
            </span>
        </div>
        <div class="polls-slider__banner-row absolute " style="bottom: 40px">
            <button
                @click="handleOpen"
                class="button button--whitebg polls-slider__btn"
            >
                Смотреть
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "VictoryWidget",
    data() {
        return {
            isBannerVisible: false
        };
    },
    methods: {
        handleOpen() {
            this.$root.logEvent(
                "Переход по виджету: Название - баннер_победы80 (W)"
            );

            window.open(" https://may9.ru", "_blank");
        }
    }
};
</script>

<style>
.victory-title {
    font-weight: 700;
    font-size: 28px;
    color: white;
}
</style>
