<script>
import DirectLineQuestion from "@/components/DirectLineQuestion";
import UiButton from "@/components/UI/UiButton";
import UiCheckbox from "@/components/UI/UiCheckbox";
import {
    getters as directLineGetters,
    mutations as directLineMutations,
    actions as directLineActions
} from "@/stores/directLine";
import DirectLineFilters from "@/components/DirectLine/DirectLineFilters.vue";
import Button from "../../voice/components/Button.vue";
import UiCircleLoader from "@/components/UI/UiCircleLoader.vue";
import { debounce } from "lodash";
export default {
    props: {
        questions: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: ""
        },
        filtersEnabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            shouldShowMyQuestions: true,
            onTop: true,
            observer: null,
            readMessages: [],
            lastViewedQuestionId: null
        };
    },
    components: {
        UiCircleLoader,
        Button,
        DirectLineFilters,
        DirectLineQuestion,
        UiButton,
        UiCheckbox
    },
    computed: {
        nextPageUrl: directLineGetters.nextPageUrl,
        unreadCount: directLineGetters.unreadCount,
        loadingQuestions: directLineGetters.areQustionsLoading,
        areMyQuestionsDisplayed: directLineGetters.areMyQuestionsDisplayed,
        unreadQuestions: function() {
            return this.questions
                .filter(question => question?.is_viewed === false)
                .map(el => el.id);
        }
    },
    methods: {
        async loadQuestions() {
            this.lastViewedQuestionId =
                this.questions.length > 0
                    ? this.questions[this.questions.length - 1].id
                    : null;
            await directLineActions.loadQuestions();
            this.$nextTick(() => {
                if (this.lastViewedQuestionId) {
                    const lastViewedQuestionElement = document.getElementById(
                        this.lastViewedQuestionId
                    );
                    if (lastViewedQuestionElement) {
                        lastViewedQuestionElement.scrollIntoView({
                            behavior: "smooth"
                        });
                    }
                }
            });
        },
        toogleMyQuestionsVisibility:
            directLineMutations.toogleMyQuestionsVisibility,
        sendReadQuestions: directLineActions.sendReadQuestions,
        loadCount: directLineActions.loadCount,
        mountQuestion(el) {
            this.observer.observe(el);
        },
        unMountQuestion(el) {
            this.observer.unobserve(el);
        },
        async sendRead() {
            if (this.readMessages.length >= 1) {
                await this.sendReadQuestions({
                    questions_id: this.readMessages
                });
                this.readMessages = [];
            }
        },
        onElementObserved(entries) {
            entries.forEach(({ target, isIntersecting }) => {
                if (!isIntersecting) {
                    return;
                }
                this.observer.unobserve(target);
                const id = Number(target.id);
                this.readMessages.push(id);
                if (
                    this.readMessages.some(
                        r => this.unreadQuestions.indexOf(r) >= 0
                    )
                ) {
                    this.unreadCount -= 1;
                    this.handleThrottleScroll();
                }
            });
        }
    },
    created() {
        this.observer = new IntersectionObserver(this.onElementObserved, {
            root: this.$el,
            threshold: 1.0
        });
    },
    mounted() {
        this.handleThrottleScroll = debounce(this.sendRead, 2000);
        this.loadCount();
    },
    beforeDestroy() {
        this.observer.disconnect();
    }
};
</script>

<template>
    <div class="direct-line-questions">
        <div class="direct-line-questions__title-wrap">
            <div style="position: absolute; top: 0" ref="top"></div>
            <h3 class="direct-line-questions__title">
                {{ title }}
            </h3>
            <slot />
        </div>
        <div
            v-if="$root.isUserSignedIn && filtersEnabled"
            class="direct-line-questions__checkbox-wrap"
            style="display: flex; flex-wrap: nowrap; justify-content: space-between; margin-top: 26px"
        >
            <ui-checkbox
                class="direct-line-questions__checkbox"
                :checked="areMyQuestionsDisplayed"
                @update="toogleMyQuestionsVisibility"
                label="Показывать мои вопросы"
            />
            <div
                style="display: flex; flex-wrap: wrap; position: relative; margin-right: 15px;"
            >
                <direct-line-filters :active="'all'"></direct-line-filters>
                <div class="counter" v-if="this.unreadCount > 0">
                    {{ this.unreadCount }}
                </div>
            </div>
        </div>

        <div
            class="direct-line-questions__list"
            v-if="questions.length > 0 && !loadingQuestions"
        >
            <direct-line-question
                ref="question"
                @mount-question="mountQuestion"
                @un-mount-question="unMountQuestion"
                class="direct-line-questions__list-item"
                v-for="question in questions"
                :key="question.id"
                :question="question"
                :id="question.id"
            />
        </div>
        <div
            class="direct-line-questions__list flex justify-center "
            v-if="questions.length <= 0 && !loadingQuestions"
        >
            <div class="font-bold mb-12 mt-12">Нет данных</div>
        </div>
        <ui-circle-loader class="mt-12" v-if="loadingQuestions" />
        <ui-button
            v-if="nextPageUrl && !loadingQuestions"
            @click.prevent="loadQuestions()"
            class="direct-line-questions__load-btn"
        >
            Загрузить еще вопросы
        </ui-button>
        <div ref="bottom"></div>
    </div>
</template>
<style>
.counter {
    position: absolute;
    top: -15px;
    right: -15px;
    background: rgb(225 17 17 / 90%);
    padding: 4px;
    border-radius: 25px;
    color: white;
    min-width: 25px;
    height: 25px;
    text-align: center;
}
</style>
