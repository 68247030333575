<script>
import UiButton from "./UI/UiButton";
import { computed, reactive, ref, watch } from "@vue/composition-api";
import { parseParams, toQuery } from "@/helpers/urlParser";
import amplitudeService from "@/services/amplitude.service";
import UiSelectNext from "@/components/UI/UiSelectNext";
import CollapseTransition from "@ivanv/vue-collapse-transition";
import UiDatePicker from "@/components/UI/UiDatePicker";
import UiButtonChip from "@/components/UI/UiButtonChip";
import { format, parse } from "date-fns";
import { DATE_FORMAT, dateToLocale, timeToLocale } from "@/helpers/dateHelpers";

const queryObject = parseParams(window.location.search, (key, value) => {
    switch (key) {
        case "start_time":
        case "end_time":
            return timeToLocale(value);
        case "start_date":
        case "end_date":
            return dateToLocale(value);
        case "categories":
            return value.split(",");
        case "is_regional":
            return value === "true";
        case "tags":
            return value.replace("#", "%23");
        default:
            return value;
    }
});

export default {
    name: "EventsFilters",
    components: {
        UiButtonChip,
        UiDatePicker,
        UiSelectNext,
        UiButton,
        CollapseTransition
    },
    props: {
        categories: {
            type: Array,
            default: () => []
        },
        dates: {
            type: Array,
            default: () => []
        },
        times: {
            type: Array,
            default: () => []
        }
    },
    setup() {
        const queryParams = reactive(
            Object.assign(
                {
                    categories: [],
                    is_archive: "",
                    date: "",
                    time: "",
                    payment_types: "",
                    start_date: null,
                    end_date: null,
                    tags: null,
                    is_regional: undefined
                },
                queryObject
            )
        );

        const isShowMore = ref(
            !!(
                queryParams.payment_types ||
                queryParams.start_date ||
                queryParams.end_date ||
                queryParams.date ||
                queryParams.time ||
                queryParams.tags ||
                queryParams.is_regional
            )
        );

        const newQueryString = computed(() =>
            toQuery(queryParams, {
                dates: ["start_date", "end_date"]
            })
        );
        const initialQuery = newQueryString.value;

        const queryHasChanged = computed(
            () => initialQuery !== newQueryString.value
        );

        const tabs = [
            { key: "active", title: "Предстоящие", value: "false" },
            { key: "archive", title: "Прошедшие", value: "true" }
        ];

        const paymentTypes = [
            { key: "free", title: "Бесплатно" },
            { key: "ticketed", title: "Платно" }
        ];
        const regionalTypes = [
            { key: undefined, title: "Все" },
            { key: true, title: "Главные" },
            { key: false, title: "Местные" }
        ];

        const customDateIsActive = computed(
            () => queryParams.start_date && queryParams.end_date
        );

        function apply() {
            amplitudeService.sendEvent("events-filter-accept");
            window.location =
                window.location.origin +
                window.location.pathname +
                "?" +
                newQueryString.value;
        }

        function reset() {
            amplitudeService.sendEvent("events-filter-reset");

            const query = "";
            window.location =
                window.location.origin + window.location.pathname + query;
        }

        function showMore() {
            isShowMore.value = !isShowMore.value;
        }

        function changePaymentType(key = "") {
            if (queryParams.payment_types === key) {
                queryParams.payment_types = "";
            } else {
                queryParams.payment_types = key;
            }
        }

        function changeRegional(key = "") {
            if (queryParams.is_regional === key) {
                queryParams.is_regional = "";
                queryParams.page = '1'
            } else {
                queryParams.is_regional = key;
                queryParams.page = '1'
            }
            queryHasChanged && apply();
        }

        function changeTime(key) {
            queryParams.time = key === queryParams.time ? undefined : key;
        }

        function changeDate(key, value = []) {
            if (key === "custom") {
                let [start_date, end_date] = value;
                if (start_date instanceof Date) {
                    start_date = format(start_date, DATE_FORMAT);
                }
                if (end_date instanceof Date) {
                    end_date = format(end_date, DATE_FORMAT);
                }
                queryParams.date = "";
                queryParams.start_date = start_date;
                queryParams.end_date = end_date;
            } else {
                queryParams.date = queryParams.date === key ? "" : key;
                queryParams.start_date = "";
                queryParams.end_date = "";
            }
        }

        const datePickerValue = computed(() =>
            customDateIsActive.value
                ? [
                      parse(queryParams.start_date, DATE_FORMAT, new Date()),
                      parse(queryParams.end_date, DATE_FORMAT, new Date())
                  ]
                : []
        );

        watch(
            () => queryParams.categories,
            category => {
                amplitudeService.sendEvent("events-filter-category", {
                    category
                });
            }
        );
        return {
            queryParams,
            queryHasChanged,
            newQueryString,
            apply,
            reset,
            showMore,
            changePaymentType,
            changeRegional,
            changeTime,
            changeDate,
            datePickerValue,
            paymentTypes,
            regionalTypes,
            isShowMore,
            initialQuery,
            tabs
        };
    }
};
</script>

<template>
    <div class="section__filters content__filters filters filters--v">
        <div class="filters__row-wrapper">
            <slot />
            <div
                class="filters__wrapper filters__wrapper--events flex flex-wrap"
            >
                <div
                    class="filters__item filters__categories mt-2"
                    style="width: 260px; height: 48px; display: flex;"
                >
                    <ui-button-chip
                        v-for="regional in regionalTypes"
                        :key="regional.key"
                        sea-color
                        class="filters-form__values-item cursor-pointer flex"
                        style="align-items: center; font-size: 15px; border-radius: 24px"
                        :active="queryParams.is_regional === regional.key"
                        @click="changeRegional(regional.key)"
                    >
                        {{ regional.title }}
                    </ui-button-chip>
                </div>
                <div class="flex flex-nowrap mt-2">
                    <div
                        v-if="queryParams.tags"
                        :class="[{ tag: !!queryParams.tags }]"
                        class="flex justify-center items-center"
                        size="lg"
                    >
                        {{ queryParams.tags.replace("%23", "#") }}
                    </div>
                    <div class="filters__item filters__categories">
                        <ui-select-next
                            style="width: 300px; border-radius: 20px"
                            v-model="queryParams.categories"
                            default-name="Вид события"
                            :items="categories"
                            item-key="short_name"
                            item-label="title"
                            show-default
                            multi
                        />
                    </div>
                    <div class="filters__item filters__actions">
                        <ui-button
                            @click="showMore"
                            size="lg"
                            :sea-bg="isShowMore"
                            >Все фильтры</ui-button
                        >
                        <ui-button
                            @click="queryHasChanged && apply()"
                            :disabled="!queryHasChanged"
                            sea-bg
                            size="lg"
                        >
                            Применить
                        </ui-button>
                        <ui-button @click="reset" mode="link" size="lg"
                            >Сбросить все</ui-button
                        >
                    </div>
                </div>
            </div>
        </div>
        <collapse-transition dimension="height" :duration="230">
            <div v-show="isShowMore" class="filters-form">
                <div class="filters-form__item">
                    <span class="filters-form__label label-padding">Стоимость</span>
                    <div class="filters-form__values">
                        <ui-button-chip
                            v-for="payment in paymentTypes"
                            sea-color
                            :key="payment.key"
                            style="border-radius: 24px"
                            class="filters-form__values-item"
                            :active="queryParams.payment_types === payment.key"
                            @click="changePaymentType(payment.key)"
                        >
                            {{ payment.title }}
                        </ui-button-chip>
                    </div>
                </div>
                <div class="filters-form__item">
                    <span class="filters-form__label label-padding">Время</span>
                    <div class="filters-form__values">
                        <ui-button-chip
                            sea-color
                            v-for="time in times"
                            :key="time.id"
                            style="border-radius: 24px"
                            @click="changeTime(time.short_name)"
                            class="filters-form__values-item"
                            :active="queryParams.time === time.short_name"
                        >
                            {{ time.title }}
                        </ui-button-chip>
                        <!--                        <ui-time-input-->
                        <!--                            label="с"-->
                        <!--                            v-model="queryParams.start_time"-->
                        <!--                            input-id="start_time"-->
                        <!--                            class="filters-form__values-item"-->
                        <!--                        />-->
                        <!--                        <ui-time-input-->
                        <!--                            label="до"-->
                        <!--                            v-model="queryParams.end_time"-->
                        <!--                            input-id="end_time"-->
                        <!--                            class="filters-form__values-item"-->
                        <!--                        />-->
                    </div>
                </div>
                <div class="filters-form__item">
                    <span class="filters-form__label label-padding">Дата</span>
                    <div class="filters-form__values">
                        <ui-button-chip
                            sea-color
                            v-for="date in dates"
                            :key="date.id"
                            style="border-radius: 24px"
                            class="filters-form__values-item"
                            @click="changeDate(date.short_name)"
                            :active="queryParams.date === date.short_name"
                        >
                            {{ date.title }}
                        </ui-button-chip>
                        <ui-date-picker
                            class="filters-form__values-item"
                            :value="datePickerValue"
                            format="yyyy-MM-dd HH:mm"
                            @input="changeDate('custom', $event)"
                        />
                    </div>
                </div>
            </div>
        </collapse-transition>
    </div>
</template>
<style scoped>
.tag {
    background: #AFD4F9 !important;
    background-position: 0px;
    background-size: 300px 150px;
    border-color: transparent;
    color: white;
    align-items: center;
    font-size: 15px;
    border-radius: 24px;
    padding: 10px 12px;
}
.label-padding {
    padding-left: 12px;
}
</style>
