<script>
import Drawer from "../UI/UIDrawer";
import UiSelect from "../UI/UiSelect";
import UiImageInput from "../UI/UiImageInput";
import YaMap from "../UI/YaMap";
import ApiService from "../../services/api.service";
import UiButton from "../UI/UiButton";
import {
    actions as anonymousProposalsActions,
    getters as anonymousProposalsGetters
} from "@/stores/anonymousProposals";

const NEW_MESSAGE_CHARACTER_LIMIT = 1000;

const STAGES = {
    ONBOARD: "onboard",
    CREATE: "create",
    CONFIRM: "confirm",
    CREATED: "created"
};

export default {
    name: "CreateAnonymousProposalDrawer",
    components: {
        UiButton,
        UiImageInput,
        UiSelect,
        Drawer,
        YaMap
    },
    data() {
        return {
            stages: STAGES,
            stage: STAGES.ONBOARD,

            maxMessageLength: NEW_MESSAGE_CHARACTER_LIMIT,
            selectCategory: "",
            message: "",
            images: [],

            loading: false,

            geo: "",
            lat: 0,
            lng: 0,

            ymaps: null
        };
    },
    computed: {
        categories: anonymousProposalsGetters.categories,

        imagesArrLength: function() {
            return this.images.filter(img => img).length;
        },

        canCreate() {
            return (
                this.message &&
                this.message.length <= this.maxMessageLength &&
                this.selectCategory &&
                this.$refs.map.coordsIsSet &&
                this.message &&
                this.coords.length === 2
            );
        },
        selectedCategory() {
            return this.categories.find(c => c.id === this.selectCategory);
        },
        coords: {
            get() {
                return [this.lat, this.lng];
            },
            set(coords) {
                [this.lat, this.lng] = coords;
            }
        }
    },
    watch: {
        selectCategory: function() {
            if (this.selectedCategory) {
                this.$amplitudeService.sendEvent("proposal-make-category", {
                    category: this.selectedCategory.name
                });
            }
        },
        imagesArrLength: function(newValue, prevValue) {
            if (this.images.length) {
                if (newValue < prevValue) {
                    this.$amplitudeService.sendEvent(
                        "proposal-make-photo-delete"
                    );
                } else {
                    this.$amplitudeService.sendEvent("proposal-make-photo-add");
                }
            }
        },
        categories: function() {
            if (this.categories.length) {
                this.selectCategory = this.categories[0].id;
            }
        }
    },
    mounted() {
        this.fetchDictionary();

        // eslint-disable-next-line no-undef
        this.ymaps = ymaps;

        this.ymaps.ready(() => {
            var suggestView = new this.ymaps.SuggestView("place", {});

            this.ymaps
                .geocode(`${this.$root.profile.city.name} Кемеровская область`)
                .then(res => {
                    this.coords = res.geoObjects
                        .get(0)
                        .geometry.getCoordinates();
                });

            suggestView.events.add("select", e => {
                const value = e.get("item").value;
                this.geo = value;

                this.ymaps.geocode(value).then(res => {
                    this.coords = res.geoObjects
                        .get(0)
                        .geometry.getCoordinates();
                });
            });
        });
    },
    methods: {
        fetchDictionary: anonymousProposalsActions.fetchDictionary,

        handleChangeCoords(coords) {
            this.coords = coords;
            this.$amplitudeService.sendEvent("proposal-make-address-map");
            this.ymaps.geocode(coords).then(res => {
                this.geo = res.geoObjects.get(0).getAddressLine();
            });
        },
        sendProposal() {
            this.loading = true;

            const loadedImages = this.images.filter(image => !!image);

            this.$amplitudeService.sendEvent("proposal-make-submit", {
                type: "anonymous",
                category: this.selectedCategory.name
            });

            ApiService.post("/anonymous_proposals", {
                category_id: this.selectCategory,
                content: this.message,
                images: loadedImages,
                lat: this.lat,
                lng: this.lng,
                geo: this.geo
            })
                .then(() => {
                    this.stage = STAGES.CREATED;

                    this.$amplitudeService.sendEvent(
                        "proposal-make-submit-ok",
                        {
                            type: "anonymous",
                            category: this.selectedCategory.name
                        }
                    );
                })
                .catch(error => {
                    this.$root.showNotification(
                        error.response.data.message,
                        error.response
                    );
                    this.stage = STAGES.CREATE;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        clearForm() {
            this.images = [];
            this.message = "";
            this.selectCategory = 1;
            this.geo = "";
            this.lat = 0;
            this.lng = 0;
            this.loading = false;

            return true;
        },
        handleClose() {
            this.clearForm();
            this.$amplitudeService.sendEvent("proposal-make-close");
            this.$root.hideDrawer({ withReplace: true });
            this.stage = STAGES.CREATE;
        }
    }
};
</script>

<template>
    <drawer @closeDrawer="handleClose">
        <template
            v-if="stage === stages.CREATE"
            v-slot:header
            class="post msg-post"
        >
            <h6 class="drawer__sub-title">Анонимное сообщение</h6>
        </template>
        <template v-slot:body>
            <div v-if="stage === stages.ONBOARD" class="post msg-post">
                <div class="post__header msg-post__header">
                    <h1 class="post__title">
                        Порядок рассмотрения <br />
                        анонимных сообщений
                    </h1>
                </div>
                <p class="text-lg text-justify pr-20 py-4">
                    1. Сообщения на тему “Наркотики” будут приняты ГУ МВД России
                    по Кемеровской области для проведения проверки.
                    <br /><br />
                    2. Ответ на сообщения на тему “Наркотики” на платформе
                    Кузбасс Онлайн предоставляться не будет.
                    <br /><br />
                    3. В случае, если вы хотите оставить официальное обращение и
                    получить официальный ответ, воспользуйтесь
                    <a
                        class="text-lg text-blue-500"
                        href="https://42.xn--b1aew.xn--p1ai/request_main"
                        >сервисом ГУ МВД России.</a
                    >
                    <br /><br />
                    4. Мы заботимся о вашей безопасности, поэтому сообщения по
                    выбранной теме являются полностью анонимными!
                    <br /><br />
                    5. Сообщения, отправленные через анонимный сервис, но не
                    соответствующие теме “Наркотики” будут отклонены. Если ваше
                    сообщение не относится к выбранной теме, пожалуйста,
                    направьте его через стандартную форму отправки обращений.
                    <br /><br />
                </p>
                <UiButton
                    style="padding-left: 30px; padding-right: 30px"
                    bg
                    size="lg"
                    @click="stage = stages.CREATE"
                >
                    Понятно
                </UiButton>
            </div>
            <div
                v-if="stage === stages.CONFIRM"
                class="post msg-post"
            >
                <div class="post__header msg-post__header">
                    <h1 class="post__title">
                        Важно
                    </h1>
                </div>
                <p class="text-lg text-justify pr-20 py-4">
                    За предоставление заведомо ложной информации предусмотрено
                    наказание в соответствии со статьёй 306 УК РФ.
                </p>
                <div class="flex space-x-4">
                    <UiButton
                        style="padding-left: 30px; padding-right: 30px"
                        size="lg"
                        :disabled="loading"
                        @click="stage = stages.CREATE"
                    >
                        Назад
                    </UiButton>
                    <UiButton
                        style="padding-left: 30px; padding-right: 30px"
                        bg
                        size="lg"
                        :loading="loading"
                        @click="sendProposal"
                    >
                        Отправить
                    </UiButton>
                </div>
            </div>
            <div v-else-if="stage === stages.CREATED"  class="post msg-post">
                <div class="post__header msg-post__header">
                    <h1 class="post__title">
                        Сообщение отправлено
                    </h1>
                </div>
                <p class="text-lg text-justify pr-20 py-4">
                    Спасибо, что делаете город лучше.
                </p>
                <ui-button size="lg" @click="handleClose">ОК</ui-button>
            </div>
            <div v-else :class="
                    stage === stages.ONBOARD ? 'create-none post msg-post' : 'create-block post msg-post'">
                <div class="post__header msg-post__header flex flex-col">
                    <h1 class="post__title">
                        Создать сообщение<br />
                        <span
                            class="inline-flex mt-2 text-xs px-5 py-2 rounded-md text-gray-500 bg-gray-100 hover:bg-gray-200 transition cursor-pointer"
                            @click="stage = stages.ONBOARD"
                            >Подробнее</span
                        >
                    </h1>
                </div>
                <div class="post__content post__create-block post-create">
                    <div class="post-create__types filters filters--v">
                        <div
                            class="filters__item filters__categories post-create__categories flex space-x-3 items-center"
                        >
                            <ui-select
                                id="dropdownMsgCategory"
                                v-model="selectCategory"
                                default-name="Тема сообщения"
                                :items="categories"
                                item-key="id"
                                item-label="title"
                            />
                            <div
                                class="w-1/2 p-4 text-xs text-red-700 bg-red-100 rounded-xl"
                            >
                                Мы заботимся о безопасности наших пользователей,
                                поэтому сообщения по выбранной теме являются
                                полностью анонимными!
                            </div>
                        </div>
                    </div>
                    <div
                        class="input-textarea__wrapper post-create__textarea-wrapper"
                    >
                        <span
                            :class="[
                                'input-textarea__character-count',
                                {
                                    'input-textarea__character-count--error':
                                        message.length > maxMessageLength
                                }
                            ]"
                        >
                            {{ message.length }} / {{ maxMessageLength }}
                        </span>
                        <textarea
                            id="textboxMsgDescription"
                            v-model="message"
                            v-amplitude:proposal-make-message.blur
                            placeholder="Я заметил(-а)..."
                            :class="[
                                'post-create__textarea',
                                'input-textarea',
                                {
                                    'input-textarea--error':
                                        message.length > maxMessageLength
                                }
                            ]"
                        />
                    </div>
                    <div class="post-create__attachments">
                        <ui-image-input
                            v-model="images[0]"
                            class="post-create__attachment-image"
                            v-amplitude:proposal-make-photo
                        />
                        <ui-image-input
                            v-model="images[1]"
                            class="post-create__attachment-image"
                            v-amplitude:proposal-make-photo
                        />
                        <ui-image-input
                            v-model="images[2]"
                            class="post-create__attachment-image"
                            v-amplitude:proposal-make-photo
                        />
                    </div>
                    <div class="post-create__place">
                        <div class="post-create__place-input custom-input">
                            <input
                                :value="geo"
                                id="place"
                                type="text"
                                placeholder=" "
                                v-amplitude:proposal-make-address-input.blur
                            />
                            <label for="place">Адрес места</label>
                            <img
                                class="custom-input__icon"
                                src="/static-web/images/icons/search.svg"
                                alt="Иконка поиска"
                            />
                        </div>
                        <div class="post__map">
                            <ya-map
                                ref="map"
                                :center="[55.354727, 86.088374]"
                                :coords="coords"
                                @input="handleChangeCoords"
                            />
                        </div>
                    </div>
                    <ui-button
                        id="btnSendMsg"
                        @click="canCreate && (stage = stages.CONFIRM)"
                        :loading="loading"
                        large
                        bold
                        :disabled="!canCreate"
                    >
                        ОТПРАВИТЬ
                    </ui-button>
                </div>
            </div>
        </template>
    </drawer>
</template>
<style>
.create-block {
    display: block;
}
.create-none {
    display: none;
}
</style>
