<script>
import LoadPosts from "../../mixins/LoadPosts";
import Drawer from "../UI/UIDrawer";
import PostAuthor from "@/components/PostAuthor";
import UiCircleLoader from "../UI/UiCircleLoader";
import PostGallery from "../PostGallery";
import YaMap from "../UI/YaMap";
import SocialSharing from "../SocialSharing";
import PostComments from "@/components/PostComments";
import SolutionRating from "@/components/Features/SolutionRating";

const postPrefix = "/proposals";

export default {
    components: {
        SolutionRating,
        YaMap,
        UiCircleLoader,
        PostAuthor,
        Drawer,
        PostGallery,
        PostComments,
        SocialSharing
    },
    mixins: [LoadPosts],
    props: {
        id: Number,
        isFromMap: {
            type: Boolean,
            default: false
        }
    },
    async created() {
        await this.fetchPost({
            path: `${postPrefix}/${this.id}`
        }).catch(() => this.$root.showNotification());
        // TODO: По другому никак не получить категорию для событие амплитуды из карты
        if (this.isFromMap) {
            const { post: proposal } = this;
            this.$amplitudeService.sendEvent("proposals-drawer-open", {
                type: proposal.type.name,
                category: proposal.category.name,
                status: proposal.status.name,
                id: proposal.id,
                src: "карта"
            });
        }
    },
    watch: {
        id(newId) {
            this.id = newId;
            this.fetchPost({ path: `${postPrefix}/${this.id}` });
        }
    },
    computed: {
        coords() {
            if (this.post.geoposition) {
                const {
                    geoposition: { lat, lng }
                } = this.post;
                return [lat, lng];
            }
            return [];
        },
        fetchParams() {
            const { postPrefix: prefix, id } = this;
            return { prefix, id };
        }
    },
    methods: {
        close() {
            if (!this.isFromMap) {
                return this.$root.hideDrawer({ withReplace: true });
            }

            const newQuery = { ...this.$route.query };
            delete newQuery.proposal;
            this.$root.hideDrawer({ replacedUrl: { query: newQuery } });
        },
        handleChangeLike(value) {
            const likeStringValue =
                value === 2 ? "лайк" : value === 1 ? "дизлайк" : undefined;
            this.$amplitudeService.sendEvent("proposals-drawer-like", {
                id: this.id,
                value: likeStringValue
            });
        }
    }
};
</script>

<template>
    <drawer :no-indent="post && post.needRating" @closeDrawer="close">
        <template v-slot:header>
            <h6 class="drawer__sub-title">Сообщения</h6>
        </template>
        <template v-slot:body >
            <ui-circle-loader v-if="postLoading || !post"></ui-circle-loader>
            <div
                v-else
                :class="[
                    'post',
                    'msg-post',
                    { 'msg-post--rating': post.needRating }
                ]"
            >
                <solution-rating
                    v-if="post && post.needRating"
                    :id="id"
                    :key="id"
                    style="padding-bottom: 10px; padding-top: 10px; margin-bottom: 60px"
                />
<!--                <div v-if="post.images[0]" style="position: relative; display: flex; justify-content: center;">-->
<!--                    <div v-if="post.after_images[0]" style="height: 500px; width: 630px;">-->
<!--                        <vue-compare-image-->
<!--                            style="height: 500px; width: 630px;"-->
<!--                            :leftImage=post.images[0].preview-->
<!--                            :rightImage=post.after_images[0].preview-->
<!--                        />-->
<!--                        <div class="footer-line">-->
<!--                            <div style="width: 80px; text-align: center;">До</div>-->
<!--                            <div style="width: 80px; text-align: center;">После</div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                        <img class="card__img" v-else alt="превью изображение до" :src=post.images[0].preview style="height: 500px; width: 630px;"/>-->
                </div>
                <div class="post__header msg-post__header" style="margin-top: 100px">
                    <span class="msg-post__category-icon">
                        <img
                            class="msg-post__category-icon-img category-icon"
                            :src="$root.getCategoryIconUrl(post.category.id)"
                            alt="Иконка категории"
                        />
                    </span>
                    <h1 class="post__title">
                        {{ post.category.name }}
                    </h1>
                    <span
                        :class="[
                            'msg-post__status',
                            'status-label',
                            `status-label--${post.status.short_name}`
                        ]"
                    >
                        {{ post.status.name }}
                        <span class="status-label__date">
                            {{ post.lastStatusUpdatedAt | parseDate("d MMMM") }}
                        </span>
                    </span>
                </div>
                <div v-if="post.author" class="post__author">
                    <post-author :author="post.author" />
                </div>

                <div class="post__content post-content">
                    <p class="post__text">
                        {{ post.message }}
                    </p>
                    <post-gallery
                        class="post__gallery"
                        :attachments="post.images"
                    />
                    <div v-if="coords.length" class="post__map">
                        <ya-map :coords="coords" />
                    </div>
                </div>

                <post-comments
                    :id="id"
                    type="proposals"
                    :likes="post.likes"
                    :comments="post.comments"
                    :responses="post.proposalResponses"
                    @changeLike="handleChangeLike"
                >
                    <social-sharing
                        post-type="proposals"
                        :object="{
                            id: post.id
                        }"
                        :title="post.message"
                    />
                </post-comments>
            </div>
        </template>
    </drawer>
</template>
<style>
.footer-line {
      position: absolute;
      background-color: white;
      display: flex;
      bottom: 0px;
      justify-content: space-between;
      border-radius: 15px 15px 0 0;
      left: 38%;
      width: 160px;
}
</style>
