<template>
    <drawer no-indent class="event-drawer" @closeDrawer="close">
        <template #header>
            <h6 class="drawer__sub-title">Событие</h6>
        </template>
        <template v-slot:body>
            <ui-circle-loader
                style="margin-top: 40px"
                v-if="!record || loading"
            />
            <div v-else class="post event-drawer__body">
                <div class="event-drawer__header">
                    <div
                        class="absolute addition-notification"
                        style="top: 0; right: 100px"
                        v-if="event.additional_category"
                    >
                        <span class="text-color-white">
                            {{ event.additional_category }}
                        </span>
                    </div>
                    <card-slider
                        class="event-drawer__img"
                        :images="
                            eventImages
                                ? eventImages
                                : ['/static-web/images/cards/default.png']
                        "
                        :preview="eventImages[0]"
                    />
                </div>
                <div class="post__content post-content">
                    <div
                        class="event-drawer__title-wrapper"
                        style="margin-bottom: 10px; margin-top: 20px"
                    >
                        <div class="event-drawer__sub-title">
                            <div
                                class="event-drawer__types space-x-5 flex justify-between flex-row w-full"
                            >
                                <div class="flex flex-row">
                                    <span
                                        v-if="showChip"
                                        :class="
                                            `event-chip ${chipClass} flex items-center text-xl mr-3`
                                        "
                                        style="height: 30px"
                                    >
                                        {{ event.payment_type.title }}
                                    </span>

                                    <span
                                        v-for="type in event.types"
                                        :key="type.id"
                                        class="text-white rounded-md flex items-center mr-3"
                                        :style="{
                                            backgroundColor:
                                                type.id === 1
                                                    ? '#B1B3C9'
                                                    : '#AFD4F9',
                                            padding: '6px 12px',
                                            height: '30px'
                                        }"
                                        >{{
                                            type.short_name === "offline"
                                                ? "офлайн"
                                                : "онлайн"
                                        }}
                                    </span>
                                </div>
                                <span
                                    v-if="event.age_rating"
                                    class="text-black py-1 px-4 flex items-center rounded-md"
                                    style="border: black solid 1px;"
                                >
                                    {{ event.age_rating }}
                                </span>
                            </div>
                        </div>
                        <h3 class="event-drawer__title">
                            {{ event.title }}
                        </h3>
                        <div class="event-drawer__info mt-8">
                            <h6 class="event-drawer__info-date">
                                {{ dates[0] }}
                                {{ dates[1] ? " - " + dates[1] : "" }}
                            </h6>
                            <h6
                                v-if="event.address"
                                class="event-drawer__info-address"
                            >
                                {{ event.address }}
                            </h6>
                            <a
                                v-for="link in event.links"
                                :key="link.id"
                                class="event-drawer__info-address event-drawer__info-address--link"
                                :href="link.url"
                            >
                                {{ link.title }}
                            </a>
                        </div>
                    </div>
                    <div class="event-drawer__info-wrapper">
                        <div
                            v-if="event.is_public"
                            @click="onFollow"
                            class="btn-drover-remind"
                            :style="{
                                backgroundColor: !!isUserFollow
                                    ? '#AFD4F9'
                                    : 'white'
                            }"
                        >
                            <img
                                src="/static-web/images/icons/bell.svg"
                                class="mr-2 bell-icon"
                                alt=""
                            />
                            {{ !!isUserFollow ? "Мы напомним" : "Напомнить" }}
                        </div>
                        <div class="relative">
                            <social-sharing
                                bigButton
                                post-type="event"
                                v-bind="sharedObject"
                                :object="event"
                            />
                        </div>
                        <button
                            @click="onBuyTicket"
                            v-if="event.external_ticket_url"
                            class="btn-ticket"
                        >
                            <img
                                style="height: 20px; width: 40px"
                                class="mr-2"
                                alt="Иконка кнопки покупки билета"
                                src="/static-web/images/icons/tikets_icon.png"
                            />
                            <span class="btn-ticket-text">Билеты</span>
                        </button>
                    </div>

                    <div class="overflow-hidden">
                        <p
                            class="post__text event-drawer__text"
                            v-html="
                                showFullText
                                    ? $options.filters.parseUrl(
                                          event.html_tag_content
                                      )
                                    : $options.filters
                                          .parseUrl(event.html_tag_content)
                                          .slice(0, 350) + '...'
                            "
                        />
                    </div>
                    <div
                        v-if="event.html_tag_content.length >= 300"
                        style="box-shadow: 0px -44px 14px 0px rgba(255, 255, 255, 0.5)"
                        class="flex flex-col items-center w-full"
                    >
                        <div class="w-full h-1 bg-white"></div>
                        <button
                            v-if="!showFullText"
                            @click="toggleFullText"
                            class="w-full flex justify-center py-2 btn-show-text"
                        >
                            {{ showFullText ? "Скрыть" : "Полное описание" }}
                        </button>
                    </div>

                    <div v-if="showFullText" class="flex flex-col">
                        <event-tags :tags="event.tags" />
                        <div class="flex flex-row justify-between mt-4">
                            <div class="event-card__types">
                                <div
                                    v-if="event.category"
                                    class="event-category"
                                >
                                    <img
                                        class="event-category__icon"
                                        alt="Иконка категории"
                                        :src="
                                            `/static-web/images/categories/events/${event.category.short_name}.svg`
                                        "
                                    />
                                    <span class="event-category__title">
                                        {{ event.category.title }}
                                    </span>
                                </div>
                            </div>
                            <div class="flex flex-row">
                                <div class="comments__count">
                                    <div
                                        class="mr-2 flex flex-row items-center"
                                    >
                                        <img
                                            style="height: 16px; width: 30px"
                                            src="/static-web/images/icons/viewed_eye.svg"
                                            alt=""
                                        />
                                        <span
                                            style="color: #B1B3C9; font-size: 12px"
                                            >{{ event.views_counter }}</span
                                        >
                                    </div>
                                    <div
                                        class="mr-2 flex flex-row items-center"
                                    >
                                        <ui-icon
                                            icon="comment"
                                            fill="none"
                                            class="mr-1"
                                        ></ui-icon>
                                        <span
                                            style="color: #B1B3C9; font-size: 12px"
                                            >{{ event.comments_count }}</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="event.organizer"
                        class="event-drawer__organizer event-organizer"
                    >
                        <img
                            v-if="event.organizer.image"
                            alt="Изображение организатора"
                            class="event-organizer__img"
                            :src="event.organizer.image"
                        />
                        <div class="event-organizer__content">
                            <div class="event-organizer__title">
                                {{ event.organizer.title }}
                            </div>
                            <div class="event-organizer__contacts">
                                <ui-icon
                                    icon="contacts"
                                    stroke="none"
                                    fill="#399CCB"
                                />
                                <div class="event-organizer__contacts-wrapper">
                                    <template v-if="event.organizer.phones">
                                        <a
                                            v-for="(phone, idx) in event
                                                .organizer.phones"
                                            :href="`tel:${phone}`"
                                            :key="idx"
                                            class="event-organizer__contact-link"
                                        >
                                            {{ phone }}
                                        </a>
                                    </template>
                                    <a
                                        :href="
                                            `mailto:${event.organizer.email}`
                                        "
                                        class="event-organizer__contact-link"
                                        v-if="event.organizer.email"
                                    >
                                        {{ event.organizer.email }}
                                    </a>
                                    <a
                                        :href="event.organizer.url"
                                        class="event-organizer__contact-link"
                                        v-if="event.organizer.url"
                                    >
                                        {{ event.organizer.url }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <post-comments
                        v-if="event.comments_enabled"
                        class="event-drawer__comments"
                        :id="id"
                        type="events"
                        :comments="event.comments"
                    />
                </div>
            </div>
        </template>
    </drawer>
</template>

<script>
import Drawer from "@/components/UI/UIDrawer";
import UiIcon from "@/components/UI/UiIcon";
import SocialSharing from "@/components/SocialSharing";
import { computed, toRefs } from "@vue/composition-api";
import { isEmpty } from "lodash";
import { useUserStore } from "@/stores/user";
import { useRecord } from "@/composables/useRecord";
import { useEvent } from "@/helpers/compositions/event";
import { useFollow } from "@/composables/useFollow";
import { useLocalization } from "@/composables/useLocalization";
import amplitudeService from "@/services/amplitude.service";
import PostComments from "@/components/PostComments";
import CardSlider from "@/components/CardSlider.vue";
import EventTags from "@/components/Events/EventTags.vue";

export default {
    name: "EventDrawer",
    components: {
        PostComments,
        SocialSharing,
        UiIcon,
        Drawer,
        CardSlider,
        EventTags
    },
    props: {
        id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            showFullText: false
        };
    },
    methods: {
        toggleFullText() {
            this.showFullText = !this.showFullText;
            if (this.showFullText) {
                this.onShowFullText();
            }
        },
        close() {
            this.$root.hideDrawer({ withReplace: true });
            this.showFullText = false;
        },
        async onBuyTicket() {
            await amplitudeService.sendEvent("events-drawer-show-buy-ticket", {
                id: this.event.id
            });
            window.open(this.event.external_ticket_url, "_blank");
        }
    },
    setup(props, ctx) {
        const { root } = ctx;
        const { id } = toRefs(props);

        const { record, loading } = useRecord("events", id);
        const { chipClass, dates, showChip } = useEvent(record);
        const { loading: followLoading, isFollowed, follow } = useFollow(
            "events",
            record
        );

        const localizedEvent = useLocalization(record, [
            "category",
            "links",
            "payment_type"
        ]);
        const event = computed(() => {
            const { value } = record;
            return value && value.is_regional ? localizedEvent.value : value;
        });

        const eventImages = computed(() => {
            return event.value.images.map(img => img.original);
        });

        const { isAuthorized } = useUserStore();

        // TODO: Исправить вместе с API
        const sharedObject = computed(() => {
            return !isEmpty(localizedEvent.value)
                ? {
                      title:
                          localizedEvent.value.title.trim().substring(0, 100) +
                          "...",
                      img: localizedEvent.value.preview
                          ? localizedEvent.value.preview.preview
                          : "/static-web/images/cards/default.png",
                      url: window.location.href
                  }
                : null;
        });

        function onFollow(active) {
            if (isAuthorized.value) {
                amplitudeService.sendEvent("events-drawer-subscribe", {
                    id: props.id,
                    value: active ? "подписаться" : "отписаться"
                });
                follow(active ? 2 : 1);
            } else {
                root.showAuth();
            }
        }

        function onShowFullText() {
            amplitudeService.sendEvent("events-drawer-show-full-text", {
                id: props.id
            });
        }

        return {
            event,
            eventImages,
            sharedObject,
            chipClass,
            dates,
            showChip,
            isUserFollow: isFollowed,
            isFollowLoading: followLoading,
            onFollow,
            loading,
            record,
            onShowFullText
        };
    }
};
</script>
<style>
.btn-show-text {
    border-radius: 10px;
    width: 200px;
    background: #afd4f9;
    color: white;
    height: 30px;
    padding: 0;
    display: flex;
    align-items: center;
}

.btn-show-text:hover {
    border: #809ab4 1px solid;
}
</style>
<style>
.btn-ticket {
    background: linear-gradient(180deg, #ff8e73 0%, #fd7352 100%);
    color: #fff;
    box-sizing: border-box;
    cursor: pointer;
    padding: 10px 12px;
    width: 180px;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
}

.btn-ticket-text {
    font-size: 14px;
    color: white;
    font-weight: 600;
}

.btn-ticket:hover {
    background: linear-gradient(180deg, #ff8e73 30%, #fd7352 70%);
}

.btn-drover-remind {
    font-size: 14px;
    color: #6f7185;
    box-sizing: border-box;
    cursor: pointer;
    padding: 10px 12px;
    width: 180px;
    height: 50px;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 24px;
    border: #afd4f9 2px solid;
    font-weight: 600;
}

@keyframes shake {
    0% {
        transform: translate(2px, 0);
    }
    25% {
        transform: translate(-2px, 0);
    }
    50% {
        transform: translate(2px, 0);
    }
    75% {
        transform: translate(-2px, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}

.bell-icon {
    transition: transform 0.3s ease;
}

.btn-drover-remind:hover .bell-icon {
    animation: shake 0.5s;
}
</style>
