<template>
    <div class="card-list__item card event-card relative">
        <div v-if="event.additional_category" class="absolute addition-notification">
            <span class="text-color-white">
                {{ event.additional_category }}
            </span>
        </div>
        <a
            class="card__picture"
            style="z-index: 1"
            @click.prevent="$root.showEventDrawer(event.id)"
            v-amplitude:events-drawer-open="{
                category: event.category.title,
                id: event.id,
                src: 'главные события',
                type: event.is_regional ? 'главное' : 'местное'
            }"
            :href="`/events/${event.id}`"
        >
            <img
                class="card__img"
                :src="
                    (event.preview && event.preview.original) ||
                        '/static-web/images/cards/default.png'
                "
                alt="Изображение события"
            />
        </a>
        <div class="card__content event-card__content">
            <div class="flex justify-between items-center mb-4 relative">
                <div>
                    <span
                        v-if="showChip"
                        class="event-chip flex event-chip--sea-wave"
                    >
                        {{ localizedEvent.payment_type.title }}
                    </span>
                </div>
                <div
                    v-if="event.age_rating"
                    class="event-card-age-rating"
                >
                    {{ event.age_rating }}
                </div>
            </div>
            <a
                class="card__header"
                @click.prevent="$root.showEventDrawer(event.id)"
                v-amplitude:events-drawer-open="{
                    category: event.category.title,
                    id: event.id,
                    src: 'главные события',
                    type: event.is_regional ? 'главное' : 'местное'
                }"
                :href="`/events/${event.id}`"
            >

                <h2 class="card__header-title">{{ cardTitle || "" }}</h2>
                <div class="card__header-row">
                    <p class="event-card__date">
                        {{ dates[0] || "" }}
                        {{ dates[1] ? "- " + dates[1] : "" }}
                    </p>
                </div>
            </a>
            <div>
                <div class="event-card__category event-category flex flex-row justify-between">
                    <div class="flex flex-row">
                        <div class="comments__count">
                            <div class="mr-2 flex flex-row items-center">
                                <img style="height: 16px; width: 30px" src="/static-web/images/icons/viewed_eye.svg"
                                     alt=""/>
                                <span style="color: #B1B3C9; font-size: 12px">{{ event.views_counter }}</span>
                            </div>
                            <div class="mr-2 flex flex-row items-center">
                                <ui-icon
                                    icon="comment"
                                    fill="none"
                                    class="mr-1"></ui-icon>
                                <span style="color: #B1B3C9; font-size: 12px">{{ event.comments_count }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="relative">
                        <social-sharing
                            outer
                            class="feed-card__sharing"
                            post-type="events"
                            :object="event"
                            :title="event.title"
                        />
                    </div>

                </div>

                <hr class="card__hr"/>
                <p class="card__text">
                    {{ localizedEvent.desc || "" }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import {SHOW_TYPE_CARD, useEvent} from "@/helpers/compositions/event";
import uiLocales from "@/components/Events/locales";
import {useLocaleStore} from "@/stores/locale";
import {computed, toRefs} from "@vue/composition-api";
import {useLocalization} from "@/composables/useLocalization";
import EventTags from "@/components/Events/EventTags.vue";
import SocialSharing from "@/components/SocialSharing.vue";

export default {
    name: "EventCard",
    components: {
        SocialSharing,
        EventTags
    },
    props: {
        event: {
            type: Object,
            required: true
        },
        locale: {
            type: String
        }
    },
    setup(props) {
        const {event} = toRefs(props);
        const localizedEvent = useLocalization(event, [
            "category",
            "payment_type"
        ]);

        const {chipClass, dates, showChip} = useEvent(event, SHOW_TYPE_CARD);

        const cardTitle = computed(() => {
            const {title} = localizedEvent.value;
            return title.length > 75 ? title.slice(0, 72) + "..." : title;
        });

        const {locale} = useLocaleStore();
        const uiLocale = computed(() => {
            return uiLocales[props.locale || locale.value];
        });
        return {
            chipClass,
            cardTitle,
            showChip,
            dates,
            uiLocale,
            localizedEvent
        };
    }
};
</script>
<style>
.addition-notification {
    z-index: 10;
    right: 5%;
    padding: 3px 15px 5px 15px;
    background-color: blueviolet;
    border-radius: 0 0 20px 20px;
}
</style>
