@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .jkh-meters__meter {
        @apply p-2 text-xl font-bold border-2 border-solid border-gray-300 bg-gradient-to-t from-gray-200 to-gray-100 rounded-md;
    }

    .jkh-meters__meter--red {
        @apply text-red-500;
    }
}
